




























































import {Vue, Component} from "vue-property-decorator";

@Component({
  name: "NewQuestResponsive",
})
export default class NewQuestResponsive extends Vue {

}
